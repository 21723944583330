<template>
  <div class="bg-off_white pb-15">
    <div
      class="bsat__container d-flex flex-column align-self-center justify-space-around fill-height"
    >
      <div>
        <v-img contain height="500px" src="@/assets/icons/hero.png"></v-img>
      </div>
      <div class="text-center">
        <div class="bsat__title notfound_title">{{ $t("pageNotFound") }}</div>
        <div class="bsat__paragraph text-center">{{ $t("paragraph") }}</div>
        <v-btn
          depressed
          class="homepage_btn bsat__btn"
          dark
          color="dark"
          to="/"
          >{{ $t("Homepage") }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<i18n>
{
	"en": {
		"homepage": "Homepage",
		"pageNotFound": "Page Not Found",
		"paragraph": "That page cannot be found. You can return to our homepage clicking on the button bellow"
	}
}
</i18n>

<script>
export default {};
</script>

<style scoped>
.homepage_btn {
  color: white !important;
  letter-spacing: normal !important;
  text-transform: none;
  font-size: 20px !important;
}
.notfound_title {
  font-size: 3rem;
}
</style>
